<!--about start-->
<section class="app2 about format">
  <!-- <div class="animated-bg"><i></i><i></i><i></i></div> -->
  <div class="container">
    <div class="row">
      <div class="col-sm-5 counters set-height">
        <img
          src="assets/images/app_landing2/mobile.png"
          alt=""
          class="img-fluid mobile1"
        />
        <!-- <img src="assets/images/app_landing2/j.png" alt="" class="img-fluid j-img"> -->
      </div>
      <div class="col-sm-7 counters">
        <div class="abouts center-text">
          <div>
            <div class="format-small-text">
              <h6 class="font-primary borders-before text-uppercase">
                <span> {{ 'headers.ourProgress' | translate }}</span>
              </h6>
            </div>
            <div class="format-head-text">
              <h3 class="about-font-header font-secondary">
                {{ 'secondHeader.header' | translate }}
              </h3>
            </div>
            <div class="format-sub-text">
              <p class="about-para">
                {{ 'secondHeader.description' | translate }}
              </p>
            </div>
            <!-- <ul class="icon-collection">
                            <li class="about-icon">
                                <a href="javascript:void(0)" class="center-content"><img
                                        src="assets/images/app_landing2/about-icons/1.png" alt="" class="img-fluid"></a>
                            </li>
                            <li class="about-icon">
                                <a href="javascript:void(0)" class="center-content"><img
                                        src="assets/images/app_landing2/about-icons/2.png" alt="" class="img-fluid"></a>
                            </li>
                            <li class="about-icon">
                                <a href="javascript:void(0)" class="center-content"><img
                                        src="assets/images/app_landing2/about-icons/3.png" alt="" class="img-fluid"></a>
                            </li>
                        </ul> -->
            <app-rr-book-demo></app-rr-book-demo>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--about end-->
