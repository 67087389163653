<!--header start-->
<section class="app2 header overflow-unset" id="home">
  <div class="app2-header bg">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-md-6">
          <div class="center-text">
            <div>
              <h6 class="header-top-line">Recognize Receipt</h6>
              <div class="header-text">
                <h1>
                  {{ 'modernHeader.headerTextOne' | translate }}
                  <span class="bold-text">{{
                    'modernHeader.headerTextTwo' | translate
                  }}</span>
                  {{ 'modernHeader.headerTextThree' | translate }}
                </h1>
              </div>
              <div class="header-sub-text">
                <p class="text-white">
                  {{ 'modernHeader.headerSubText' | translate }}
                </p>
              </div>
              <div class="link-horizontal">
                <ul>
                  <li>
                    <app-rr-get-app-button isTransparent="true"></app-rr-get-app-button>
                  </li>
                  <li>
                    <app-rr-book-demo isTransparent="true"></app-rr-book-demo>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-md-6">
          <div class="img-mobile set-abs">
            <img
              src="../assets/images/app_landing2/h2-mobile.png"
              alt=""
              class="headaer-image"
            />
          </div>
          <!-- <div class="wave-orange"></div> -->
        </div>
      </div>
    </div>
    <div class="wave"></div>
  </div>
</section>
<!--header end-->
