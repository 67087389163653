<app-modern-nav></app-modern-nav>
<app-modern-header id="Home"></app-modern-header>
<app-modern-about id="App"></app-modern-about>
<app-modern-services id="Features"></app-modern-services>
<app-modern-function></app-modern-function>
<!-- <app-modern-team></app-modern-team> -->
<app-modern-screenshots id="Screens"></app-modern-screenshots>
<!-- <app-modern-team-member></app-modern-team-member>
<app-modern-pricing></app-modern-pricing> -->
<!-- <app-modern-brand></app-modern-brand> -->
<app-modern-subscribe id="Contact"></app-modern-subscribe>
<app-modern-footer></app-modern-footer>