import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modern-header',
  templateUrl: './modern-header.component.html',
  styleUrls: ['./modern-header.component.scss']
})
export class ModernHeaderComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
