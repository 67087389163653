import { FsRrCommonNameService } from './../../../shared/service/fs-rr-common-name.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modern-footer',
  templateUrl: './modern-footer.component.html',
  styleUrls: ['./modern-footer.component.scss']
})
export class ModernFooterComponent implements OnInit {

  constructor(public fsRrCommonNameService: FsRrCommonNameService) { }

  ngOnInit() {
  }

}
