import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-rr-book-demo',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './rr-book-demo.component.html',
  styleUrl: './rr-book-demo.component.scss'
})
export class RrBookDemoComponent {
  @Input() isTransparent = false;
}
