import { RrGetAppButtonComponent } from './../shared/components/rr-get-app-button/rr-get-app-button.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SwiperModule } from 'swiper/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMasonryModule } from 'ngx-masonry';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from 'ngx-slider-v2';
import { SharedModule } from '../shared/shared.module';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';

// Modern Layout
import { ModernComponent } from './modern/modern.component';
import { ModernNavComponent } from './modern/modern-nav/modern-nav.component';
import { ModernHeaderComponent } from './modern/modern-header/modern-header.component';
import { ModernServicesComponent } from './modern/modern-services/modern-services.component';
import { ModernAboutComponent } from './modern/modern-about/modern-about.component';
import { ModernFunctionComponent } from './modern/modern-function/modern-function.component';
import { ModernTeamComponent } from './modern/modern-team/modern-team.component';
import { ModernScreenshotsComponent } from './modern/modern-screenshots/modern-screenshots.component';
import { ModernTeamMemberComponent } from './modern/modern-team-member/modern-team-member.component';
import { ModernPricingComponent } from './modern/modern-pricing/modern-pricing.component';
import { ModernBrandComponent } from './modern/modern-brand/modern-brand.component';
import { ModernSubscribeComponent } from './modern/modern-subscribe/modern-subscribe.component';
import { ModernFooterComponent } from './modern/modern-footer/modern-footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { RrBookDemoComponent } from '../shared/components/rr-book-demo/rr-book-demo.component';

@NgModule({
  declarations: [
    ModernComponent,
    ModernNavComponent,
    ModernHeaderComponent,
    ModernServicesComponent,
    ModernAboutComponent,
    ModernFunctionComponent,
    ModernTeamComponent,
    ModernScreenshotsComponent,
    ModernTeamMemberComponent,
    ModernPricingComponent,
    ModernBrandComponent,
    ModernSubscribeComponent,
    ModernFooterComponent,
  ],

  imports: [
    CommonModule,
    LayoutsRoutingModule,
    SwiperModule,
    CarouselModule,
    NgbModule,
    GalleryModule,
    LightboxModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,
    NgxMasonryModule,
    TranslateModule.forChild(),
    RrBookDemoComponent,
    RrGetAppButtonComponent
  ],

  exports: [],
})
export class LayoutsModule {}
