<!--function start-->
<section class="app2 format quality p-t-0">
  <!-- <div class="animated-bg"><i></i><i></i><i></i></div> -->
  <div class="container">
    <div class="row">
      <div class="col-sm-6 counters set-order-2">
        <div class="abouts center-text">
          <div>
            <div class="format-head-text">
              <h3 class="about-font-header font-secondary">
                {{ 'headers.modernFunction' | translate }}
              </h3>
            </div>
            <div class="format-sub-text">
              <p class="about-para">
               {{ 'thirdHeader.description' | translate }}
              </p>
            </div>
            <!-- <ul class="icon-collection">
                            <li class="about-icon">
                                <a href="#" class="center-content">
                                    <h4 class="quality">2 M</h4>
                                    <h6 class="users ">user</h6>
                                </a>
                            </li>
                            <li class="about-icon">
                                <a href="#" class="center-content">
                                    <h4 class="quality">2.5 M</h4>
                                    <h6 class="users">download</h6>
                                </a>
                            </li>
                        </ul> -->
            <!-- <a class="btn btn-default btn-gradient m-t-45">learn more</a> -->

            <div class="col-md-10 offset-md-1 text-center mt-5">
              <app-rr-get-app-button></app-rr-get-app-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-sm-6 counters set-height overflow-hide"
        [@fadeInRight]="fadeInRight"
      >
        <img
          src="assets/images/app_landing2/l-2.png"
          alt=""
          class="img-fluid mobile2"
          data-aos-duration="1500"
        />
      </div>
    </div>
  </div>
</section>
<!--function end-->
