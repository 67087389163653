<a
  class="overlay-sidebar-header"
  [class.overlay-sidebar-header-open]="openSide"
  (click)="closeOverlay()"
></a>
<div class="responsive-btn">
  <a (click)="toggleSidebar()">
    <i class="fa fa-bars text-white" aria-hidden="true"></i>
  </a>
</div>
<div class="navbar m-l-auto" id="togglebtn" [class.openSidebar]="openSide">
  <div class="responsive-btn">
    <a (click)="toggleSidebar()">
      <h5>back</h5>
    </a>
  </div>
  <!-- <ul class="main-menu">
   1st Level Menu 
    <li
      *ngFor="let menuItem of menuItems"
      [class]="menuItem.megaMenu ? 'mega-menu' : ''"
    >
     
      <a
        href="javascript:void(0)"
        *ngIf="menuItem.type === 'sub'"
        class="dropdown"
        (click)="setActive(menuItem.title)"
      >
        {{ menuItem.title }}
      </a>
    
      <a
        routerLink="/"
        routerLinkActive="active"
        class="dropdown"
        *ngIf="menuItem.type === 'link'"
      >
        {{ menuItem.title }}
      </a>
     
      <a
        href="javascript:void(0)"
        class="dropdown"
        *ngIf="menuItem.type === 'extLink'"
      >
        {{ menuItem.title }}
      </a>
     
      <a
        href="javascript:void(0)"
        *ngIf="menuItem.type === 'extTabLink'"
        (click)="scrollTo(menuItem.title)"
      >
        {{ menuItem.title }}
      </a>

     
      <div
        class="mega-menu-container"
        [class.opensubmenu]="isActive(menuItem.title)"
        *ngIf="
          (menuItem.megaMenu && menuItem.megaMenuType == 'small') ||
          menuItem.megaMenuType == 'medium' ||
          menuItem.megaMenuType == 'large'
        "
      >
        <div class="container">
          <div class="row">
            <div
              [class]="
                menuItem.megaMenuType == 'small'
                  ? 'col-lg-4'
                  : menuItem.megaMenuType == 'medium'
                  ? 'col-lg-3'
                  : menuItem.megaMenuType == 'large'
                  ? 'col'
                  : ''
              "
              *ngFor="let childrenItem of menuItem.children"
            >
              <div class="menu-container">
                <a
                  class="menu-head"
                  (click)="setChildActive(childrenItem.title)"
                  >{{ childrenItem.title }}</a
                >
                <ul
                  [class.menu-icon]="menuItem.title == 'Elements'"
                  [class.openSubChildMenu]="ischildActive(childrenItem.title)"
                >
                  <li *ngFor="let subChildrenItem of childrenItem.children">
                    <a
                      routerLink="/"
                      routerLinkActive="active"
                      *ngIf="subChildrenItem.type === 'link'"
                    >
                      <i class="icon-{{ subChildrenItem.icon }}"></i
                      >{{ subChildrenItem.title }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

     
      <ul
        *ngIf="menuItem.children && !menuItem.megaMenu"
        [class.opensubmenu]="isActive(menuItem.title)"
      >
       
        <li
          *ngFor="let childrenItem of menuItem.children"
          [class.sub-menu]="childrenItem.children"
        >
        
          <a
            href="javascript:void(0)"
            *ngIf="childrenItem.type === 'sub'"
            (click)="setChildActive(childrenItem.title)"
          >
            {{ childrenItem.title }}
          </a>
        
          <a
            routerLink="/"
            routerLinkActive="active"
            *ngIf="childrenItem.type === 'link' && !menuItem.megaMenu"
          >
            {{ childrenItem.title }}
          </a>
         
          <a
            href="javascript:void(0)"
            *ngIf="childrenItem.type === 'extLink' && !menuItem.megaMenu"
          >
            {{ childrenItem.title }}
          </a>
         
          <a
            href="javascript:void(0)"
            target="_blank"
            *ngIf="childrenItem.type === 'extTabLink' && !menuItem.megaMenu"
          >
            {{ childrenItem.title }}
          </a>
          <ul
            *ngIf="childrenItem.children"
            [class.openSubChildMenu]="ischildActive(childrenItem.title)"
          >
            <li *ngFor="let subChildrenItem of childrenItem.children">
         
              <a
                href="javascript:void(0)"
                *ngIf="subChildrenItem.type === 'sub'"
              >
                {{ subChildrenItem.title }}
              </a>
            
              <a
                routerLink="/"
                routerLinkActive="active"
                *ngIf="subChildrenItem.type === 'link' && !menuItem.megaMenu"
              >
                {{ subChildrenItem.title }}
              </a>
            
              <a
                href="javascript:void(0)"
                *ngIf="subChildrenItem.type === 'extLink' && !menuItem.megaMenu"
              >
                {{ subChildrenItem.title }}
              </a>
             
              <a
                href="javascript:void(0)"
                target="_blank"
                *ngIf="
                  subChildrenItem.type === 'extTabLink' && !menuItem.megaMenu
                "
              >
                {{ subChildrenItem.title }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul> -->
  <ul class="main-menu">
    <li>
      <a href="javascript:void(0)" (click)="scrollTo('Home')">
        {{ 'nav.home' | translate }}
      </a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="scrollTo('App')">
        {{ 'nav.app' | translate }}
      </a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="scrollTo('Features')">
        {{ 'nav.features' | translate }}
      </a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="scrollTo('Screens')">
        {{ 'nav.screens' | translate }}
      </a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="scrollTo('Contact')">
        {{ 'nav.contact' | translate }}
      </a>
    </li>
  </ul>
  <select
    [(ngModel)]="selectedLanguage"
    (change)="selectLanguage(selectedLanguage)"
  >
    <option *ngFor="let language of languages" [value]="language">
      {{ language }}
    </option>
  </select>
</div>
