import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FsRrCommonNameService {

  constructor() { }

  get AppName() {
    return "Recognize Receipt"
  }

  get DateFullYear() {
    return new Date().getFullYear();
  }
}
