@if (isTransparent){
<a
  class="btn btn-default btn-white"
  href="https://recognizereceipt.herokuapp.com/login"
  target="_blank"
>
  {{ 'buttonGetApp' | translate }}</a
>
} @else {
<a
  class="btn btn-default primary-btn gradient"
  href="https://recognizereceipt.herokuapp.com/login"
  target="_blank"
>
  {{ 'buttonGetApp' | translate }}</a
>
}
