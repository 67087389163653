<!--services start-->
<section class="app2 services p-t-0" id="services">
  <div class="container">
    <div class="row">
      <div class="col-md-10 offset-md-1">
        <div class="title title2">
          <img
            src="assets/images/logo/2.png"
            alt=""
            class="img-fluid title-img"
          />
          <h6 class="font-primary borders main-text text-uppercase">
            <span>{{ 'headers.feature' | translate }}</span>
          </h6>
          <div class="sub-title">
            <h2 class="title-text text-capitalize text-center">
              {{ 'headers.features' | translate }}
            </h2>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 service-container">
        <div class="service text-center">
          <div class="img-block mb-4">
            <img
              src="assets/images/app_landing2/service/2-2.png"
              alt=""
              class="service-img img-fluid"
            />
          </div>
          <div class="service-feature">
            <h4 class="feature-text text-center">
              {{ 'features.scan.header' | translate }}
            </h4>
            <p class="text-center">
              {{ 'features.scan.description' | translate }}
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 service-container">
        <div class="service text-center">
          <div class="img-block mb-4">
            <img
              src="assets/images/app_landing2/service/2-2.png"
              alt=""
              class="service-img img-fluid"
            />
          </div>
          <div class="service-feature">
            <h4 class="feature-text text-center">
              {{ 'features.tasksAndCalendar.header' | translate }}
            </h4>
            <p class="text-center">
              {{ 'features.tasksAndCalendar.description' | translate }}
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 service-container">
        <div class="service text-center">
          <div class="img-block mb-4">
            <img
              src="assets/images/app_landing2/service/2-3.png"
              alt=""
              class="service-img img-fluid"
            />
          </div>
          <div class="service-feature">
            <h4 class="feature-text text-center">
              {{ 'features.reminder.header' | translate }}
            </h4>
            <p class="text-center">
              {{ 'features.reminder.description' | translate }}
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 service-container">
        <div class="service text-center">
          <div class="img-block mb-4">
            <img
              src="assets/images/app_landing2/service/2-1.png"
              alt=""
              class="service-img img-fluid"
            />
          </div>
          <div class="service-feature">
            <h4 class="feature-text text-center">
              {{ 'features.autoBill.header' | translate }}
            </h4>
            <p class="text-center">
              {{ 'features.autoBill.description' | translate }}
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 service-container">
        <div class="service text-center">
          <div class="img-block mb-4">
            <img
              src="assets/images/app_landing2/service/2-1.png"
              alt=""
              class="service-img img-fluid"
            />
          </div>
          <div class="service-feature">
            <h4 class="feature-text text-center">
              {{ 'features.customize.header' | translate }}
            </h4>
            <p class="text-center">
              {{ 'features.customize.description' | translate }}
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 service-container">
        <div class="service text-center">
          <div class="img-block mb-4">
            <img
              src="assets/images/app_landing2/service/2-5.png"
              alt=""
              class="service-img img-fluid"
            />
          </div>
          <div class="service-feature">
            <h4 class="feature-text text-center">
              {{ 'features.multiUsers.header' | translate }}
            </h4>
            <p class="text-center">
              {{ 'features.multiUsers.description' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--services end-->
