import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Unice';

  constructor(private translate: TranslateService) {
    // console.log('Culture Lang: ' + this.translate.getBrowserCultureLang());
    // console.log('Browser Lang: ' + this.translate.getBrowserLang());
    // console.log('Default Lang: ' + this.translate.getDefaultLang());
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('en');
  }
}
