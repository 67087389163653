@if (isTransparent){
  <a
  class="btn btn-default primary-btn transparent"
  href="https://app.zencal.io/u/recognizereceipt"
  target="_blank"
>
  {{ 'buttonBooksSession' | translate }}</a
>
} 
@else {
  <a
  class="btn btn-default primary-btn gradient"
  href="https://app.zencal.io/u/recognizereceipt"
  target="_blank"
>
  {{ 'buttonBooksSession' | translate }}</a
>
}
