import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../../service/nav.service';
import { ViewportScroller } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public menuItems: Menu[];
  public openSide: boolean = false;
  public activeItem: string = 'home';
  public active: boolean = false;
  public activeChildItem: string = '';
  public overlay: boolean = false;
  languages: string[] = [
    'English', 
    'Polski',
  ];

  selectedLanguage: string = 'English';
  constructor(
    public navServices: NavService,
    private scroller: ViewportScroller,
    private translate: TranslateService
  ) {}

 

  selectLanguage(language: string): void {
    this.selectedLanguage = language;
    if(this.selectedLanguage === 'English') {
      this.translate.use('en');
      return;
    }
    if(this.selectedLanguage === 'Polski') {
      this.translate.use('pl');
      return;
    }
  }

  ngOnInit() {
    this.navServices.items.subscribe((menuItems) => {
      this.menuItems = menuItems;
    });
  }

  toggleSidebar() {
    this.openSide = !this.openSide;
  }

  closeOverlay() {
    this.openSide = false;
  }

  //For Active Main menu in Mobile View
  setActive(menuItem) {
    if (this.activeItem === menuItem) {
      this.activeItem = '';
    } else {
      this.activeItem = menuItem;
    }
  }

  isActive(item) {
    return this.activeItem === item;
  }

  // For Active Child Menu in Mobile View
  setChildActive(subMenu) {
    if (this.activeChildItem === subMenu) {
      this.activeChildItem = '';
    } else {
      this.activeChildItem = subMenu;
    }
  }

  ischildActive(subMenu) {
    return this.activeChildItem === subMenu;
  }

  scrollTo(value: string) {
    document.getElementById(value).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
}
